$height_vh: (7, 10,  28, 37, 56, 62, 90, 100,);

@each $size in $height_vh {
  .height-#{$size}vh {
    height: $size+vh;
  }
}

$height_px: (15, 45, 50, 60, 90, 235);

@each $size in $height_px {
  .height-#{$size}px {
    height: $size+px;
  }
}

$min-height_px: (90, 235, 330);

@each $size in $min-height_px {
  .min-height-#{$size}px {
    min-height: $size+px;
  }
}
