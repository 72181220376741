@import './variables';
@import './fonts';

.header {
  width: 100%;
  z-index: 50;
  background-color: transparent;
  &_sticky {
    position: sticky;
    top: 0;
    transition: background-color 1.5s ease;
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  &_menu_shown {
    position: fixed;
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  &__body {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    padding: 0 15px;
    @media (min-width: $medium-start) {
      height: 90px;
      padding: 0;
    }
  }

  &__logo {
    z-index: 3;
    img {
      width: 60px;
      @media (min-width: $medium-start) {
        width: 75px;
      }
      @media (min-width: $large-start) {
        width: 90px;
      }
    }
  }

  &__btn-burger {
    display: block;
    @media (min-width: $medium-start) {
      display: none;
    }
		position: relative;
    z-index: 3;
    border: none;
    background: none;
    align-items: center;
    margin-bottom: 0;
    width: 18px;
    height: 14px;
    cursor: pointer;
    &__wrapper {
      @media (max-width: $small-end) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        height: 50px;
        z-index: 999;
        padding-right: 15px;
        margin-right: -15px;
        padding-left: 10px;
        margin-left: 5px;
      }
    }
    &:hover {
      outline: none;
      span,
      span:before,
      span:after {
        box-shadow: 0 0 2px $color-accent2;
      }
    }
    span,
    span:before,
    span:after {
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $color-accent1;
      transition-duration: 0.25s;
    }
    span:before {
      content: '';
      top: -6px;
    }
    span:after {
      content: '';
      top: 6px;
    }
    &_cross {
      span {
        transform: rotate(45deg);
        &:before {
          top: 0;
          transform: rotate(0);
        }
        &:after {
          top: 0;
          transform: rotate(90deg);
        }
      }
    }
  }

  &__menu {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    z-index: 2;
    background-image: url('../assets/images/m-header-background.svg');
    transition: all 0.3s ease 0s;
    overflow: auto;
    &_visible {
      visibility: visible;
      top: 50px;
    }
    @media (min-width: $medium-start) {
      position: static;
      visibility: visible;
      background: none;
      padding: 0;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: visible;
      z-index: 3;
    }
  }
}

.navigation {
  padding: 10px 0 30px 25px;
  @media (min-width: $medium-start) {
    padding: 0;
  }
  &__ul {
    display: block;
    @extend .font-h4new;
    @media (min-width: $medium-start) {
      display: flex;
      position: relative;
      z-index: 2;
    }
  }
  &__item {
    z-index: 101;
    display: block;
    margin-top: 30px;
    white-space: nowrap;
    a {
      color: white;
      @media (min-width: $medium-start) {
        color: black;
      }
    }
    @media (min-width: $medium-start) {
      font-family: $font-family-source-sans-pro;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      line-height: 100%;
      margin-top: 0;
    }
    @media (min-width: $medium-start) and (max-width: $medium-end) {
      margin-left: 15px;
      text-align: center;
      // need to get form .font-m4
      font-size: 12px;
    }
    @media (min-width: $large-start) and (max-width: $large-end) {
      margin-left: 30px;
    }
    @media (min-width: $extra-large) {
      margin-left: 45px;
    }
    button:hover,
    a:hover {
      cursor: pointer;
      transition: 0.2s;
      color: $color-accent2;
      text-decoration: none;
      @media (min-width: $medium-start) {
        border-bottom: $color-accent1 2px solid;
      }
    }
  }
  .selected {
    color: $color-accent2;
    @media (min-width: $medium-start) {
      border-bottom: $color-accent1 2px solid;
      color: black;
    }
  }
}

.nav-item-dropdown {
  button {
    background: none;
    border: none;
    border-bottom: none !important;
  }
  &__button {
    color: white;
    @media (min-width: $medium-start) {
      width: 100%;
      text-align: left;
      padding-bottom: 2px;
      color: black;
    }
  }
  &__list {
    color: white;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    @media (min-width: $medium-start) {
      position: absolute;
      top: 35px;
      border-top: solid 4px $color-accent1;
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.25);
      background-color: white;
      min-width: 200px;
      color: black;
      margin-left: initial;
    }
  }
  &__item {
    font-family: $font-family-source-sans-pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    margin-top: 15px;
    @media (min-width: $medium-start) {
      text-align: center;
      padding: 17.5px 0;
      margin-top: 0;
      font-size: 15px;
    }
  }
  &__button, &__item {
    border-bottom: 2px solid transparent;
    &:hover {
      border-bottom: $color-accent1 2px solid;
      @media (min-width: $medium-start) {
        color: $color-accent2;
      }
    }
  }
}

.profile-dropdown {
  display: flex;
  margin-top: 30px;
  margin-left: 15px;
  @media (min-width: $medium-start) {
    position: relative;
    margin-top: inherit;
    margin-right: 20px;
  }
  &__button {
    background: $color-light-gray;
    width: 60px;
    height: 60px;
    @media (min-width: $medium-start) {
      height: 34px;
    }
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    .dropdown__button {
      @media (max-width: $small-end) {
        // .font-h4new;
        font-family: $font-family-bebas-neue;
        font-size: 30px;
      }
      @media (min-width: $medium-start) {
        // .font-m3;
        font-family: $font-family-source-sans-pro;
        font-weight: 600;
        font-size: 15px;
      }
    }
    .dropdown__icon {
      display: none;
    }
    @media (min-width: $medium-start) {
      min-width: 60px;
      background: white;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      .dropdown__icon {
        display: block;
        position: relative;
        &_opened-single {
          position: relative;
          top: 3px;
        }
      }
    }
  }
  &__list {
    z-index: 1;
    @extend .font-m2;
    .nav-item-dropdown__item {
      color: white;
      display: block;
      @media (min-width: $medium-start) {
        color: black;
      }
      &:hover {
        cursor: pointer;
        color: $color-accent2;
        text-decoration: none;
      }
      @media (max-width: $small-end) {
        &.selected {
          color: $color-accent2;
        }
      }
    }
  }
  .nav-item-dropdown__list {
    z-index: 100;
    color: white;
    @media (min-width: $medium-start) {
      display: none; // need to change
      position: absolute;
      top: 60px;
      right: 15px;
      &_opened {
        display: block;
      }
    }
  }
  .nav-item-dropdown__item {
    margin-top: 8px;
    @media (min-width: $medium-start) {
      margin-top: inherit;
    }
  }
}

.header-sign-in-buttons {
  display: flex;
  width: 320px;
  margin-left: 15px;
  @media (min-width: $medium-start) {
    justify-content: space-between;
  }
  @media (min-width: $medium-start) and (max-width: $medium-end) {
    width: 205px;
    margin-right: 0;
  }
  @media (min-width: $large-start) {
    margin-right: 25px;
    width: 205px;
  }
  button {
    font-size: 18px;
    font-weight: normal;
    font-family: $font-family-bebas-neue;

    @media (min-width: $medium-start) {
      width: 40%;
      font-family: $font-family-source-sans-pro;
      font-size: 15px;
      font-weight: 600;
      line-height: 100%;
    }
    @media (max-width: $small-end) {
      &.sign-in-btn {
        margin-right: 20px !important;
      }
    }
  }
}

body.lock {
  @media (max-width: $small-end) {
    overflow: hidden;
  }
}

.language-select {
  z-index: 3;
  height: 40px;
  margin-left: auto;
  padding-left: 15px;
  @media (max-width: $small-end) {
    height: 30px;
  }
  overflow: visible;
  position: relative;

  button {
    border: none;
    background: none;
    margin: 0;
    display: flex;
    align-items: center;
    height: 100%;

    .icon {
      border-radius: 50%;
  
      margin-left: 5px;
      &.opened {
        transform: rotate(180deg);
      }
    }
  }

  .collapsing, .collapse {
    border-top: solid 4px $color-accent1;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.25);
    background-color: white;
    min-width: 120px;
    padding: 0 15px;
    color: black;
    position: absolute;
    top: 50px;
    right: 0;

    @media (max-width: $small-end) {
      top: 39px;
      padding: 0 22.5px;
    }

    .item {
      padding: 15px 0;
      height: 70px;
      @media (max-width: $small-end) {
        height: 60px;
      }

      button {
        text-transform: uppercase;
        font-size: 15px;
        font-family: Source Sans Pro;
        font-weight: 600;

        img {
          margin-right: 15px;
        }
      }

      &.selected {
        button {
          color: $color-accent1;
        }
      }
    }  
  }

  .language-icon {
    padding: 2px;
    height: 100%;
    border-radius: 50%;
    border: 2px solid $color-d1;
    &.selected {
      border-color: $color-accent1;
    }
  }
}