.slider-wrapper {
  height: 25px;
  width: 100%;
}

.slider-main {
  position: relative;
  width: 100%;
}

.slider-rail {
  position: absolute;
  width: 100%;
  transform: translate(0%, -50%);
  height: 40px;
  cursor: pointer;
  z-index: 300;
}

.slider-rail-center {
  position: absolute;
  width: 100%;
  transform: translate(0%, -50%);
  height: 4px;
  cursor: pointer;
  pointer-events: none;
  background-color: #D1D1D1;
}

.slider-tooltip {
  position: absolute;
  top: 16px;
  min-width: 50px;
  left: -16px;
}

.slider-tooltip-text {
  @extend .source-sans-pro;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #008FD7;
  width: 70px;
  flex-direction: row;
  &.normalize-left {
    margin-left: 10px;
  }
  &.normalize-right {
    margin-left: -10px;
  }
}

.rail-tooltip {
  margin-top: 32px;
}

.slider-track {
  height: 4px;
  position: absolute;
  transform: translate(0%, -50%);
  z-index: 1;
  cursor: pointer;
}

.slider-handle {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 14px;
  height: 14px;
  background: #FFFFFF;
  border: 4px solid $color-accent1;
}