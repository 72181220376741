.tabs-main {
  .tabs {
    border: none;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    &_mt {
      margin-top: 13px;
      @media screen and (min-width: $medium-start) {
        margin-top: 32px;
      }
    }
    &_mb {
      margin-bottom: 20px;
      @media screen and (min-width: $medium-start) {
        margin-bottom: 40px;
      }
    }
    &__wrapper {
      display: flex;
      justify-content: space-around;
    }
    &__item {
      margin: 0 5px;
      width: 90px;
      @media (min-width: $medium-start) {
        width: 140px;
      }
      cursor: pointer;
      &:hover {
        outline: none;
      }

      a {
        display: flex;
        align-items: center;
        height: 100%;
        color: black;
        &:hover {
          @media (min-width: $medium-start) {
            color: $color-accent2;
            border-bottom: solid 2px $color-accent1;
          }
        }
      }
      .active {
        color: $color-accent1;
        border-bottom: solid 2px $color-accent1;
      }
    }
    &__link {
      border: none;
      @extend .font-h7;
      padding: 7px;
      padding-bottom: 5px;
      text-align: center;
      margin: 0 10px;
      @media (min-width: $medium-start) {
        font-size: 24px;
        padding-bottom: 11px;
      }
    }
  }
}
