.round-board {
  &_border {
    @extend .schedule-border;
  }
  &_mt {
    margin-top: 20px;
    @media screen and (min-width: $medium-start) {
      margin-top: 40px;
    }
  }
  &__header {
    padding: 12px 0;
    @extend .font-m2;
    @media screen and (min-width: $medium-start) {
      padding: 18px 0;
    }
    &_blue {
      background-color: $color-accent1;
      background-image: url('../assets/images/board-bg.svg');
      @extend .color-white;
    }
    &_gray {
      background-color: $color-light-gray;
      @extend .color-black;
    }
    @media screen and (max-width: $small-end) {
      &_hover {
        transition: 0.3s;
        &:hover {
          box-shadow: 0 0 5px $color-accent1;
          cursor: pointer;
        }
      }
    }
  }
  &__title {
    font-weight: 500;
    font-size: 15px;
    @media screen and (min-width: $medium-start) {
      font-size: 20px !important;
    }
  }
  &_mt-blue {
    margin-top: 20px;
    @media screen and (min-width: $medium-start) {
      margin-top: 40px;
    }
  }
  &_mt-gray {
    margin-top: 20px;
    @media screen and (min-width: $medium-start) {
      margin-top: 30px;
    }
  }
}

.game-item-wrapper {
  position: relative;
  @extend .schedule-border;
  width: 47%;
  margin-top: 20px;
  margin-bottom: -1px;
  margin-right: 5.9%;
  @media screen and (min-width: $medium-start) {
    margin-top: 40px;
    margin-bottom: -2px;
  }
  @media screen and (max-width: $small-end) {
    &:nth-child(odd) {
      left: -1px;
    }
    &:nth-child(2n) {
      left: 1px;
      margin-right: 0;
    }
  }
  @media screen and (min-width: $medium-start) and (max-width: $medium-end) {
    &:nth-child(odd) {
      left: -2px;
    }
    &:nth-child(2n) {
      left: 3px;
      margin-right: 0;
    }
  }
  @media screen and (min-width: $large-start) and (max-width: $large-end) {
    width: 31%;
    margin-right: 3.5%;
    &:nth-child(3n+1) {
      left: -2px;
    }
    &:nth-child(3n) {
      margin-right: 0;
      left: 3px;
    }
  }
  @media screen and (min-width: $extra-large) {
    width: 22%;
    margin-right: 4%;
    &:nth-child(4n+1) {
      left: -2px;
    }
    &:nth-child(4n) {
      margin-right: 0;
      left: 2px;
    }
  }
}

.game-item {
  padding: 6px 4px 4px;
  @media screen and (max-width: 350px) {
    padding: 6px 8px 4px;
    &__team {
      padding-bottom: 17px;
      span {
        position: absolute;
        bottom: 0;
      }
    }
  }

  @media screen and (min-width: $medium-start) {
    padding: 20px 5px 29px;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__team {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 140px;
    img {
      max-width: 100%;
      width: 40px;
      object-fit: contain;
      @media screen and (min-width: $medium-start) {
        width: 50px;
      }
    }
    span {
      margin-top: 6px;
    }
    @extend .font-m2;
    @media screen and (max-width: $extra-small-end) {
      font-size: 13px !important;
    }
  }
  &__score {
    @extend .font-h6;
    font-weight: bold;
    max-width: 50px;
    @media screen and (min-width: $medium-start) {
      font-size: 36px !important;
    }
    align-self: center;
  }
  .status {
    position: absolute;
    margin-top: -16px;
  }
  &__info-button {
    display: none;
    position: absolute;
    bottom: 2px;
    left: calc(50% - 15px);
    border: none;
    border-radius: 0;
    background-color: $color-accent1;
    width: 30px;
    height: 30px;
    color: white;
    transition: 0.2s;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    font-family: sans-serif;
    text-align: center;
    vertical-align: middle;
    @media screen and (max-width: $small-end) {
      &_active {
        display: block;
      }
    }
    @media screen and (min-width: $medium-start) {
      left: calc(50% - 12px);
      bottom: 5px;
      display: block;
      border-radius: 0;
      width: 24px;
      height: 24px;
      font-size: initial;
      padding: 0;
    }
    &:hover {
      transform: scale(1.2);
    }
  }

  &__yellow-title {
    width: 100%;
    background-color: $color-accent2;
    height: 25px;
    @extend .font-m4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    @media screen and (max-width: $small-start) {
      font-size: 10px !important;
    }
  }

  &__player-stats-block {
    border: 2px solid #F2F2F2;
    margin: 0 0 30px;
  }

  &__player-stats-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .font-m2;
    height: 42px;
    min-width: 200px;
    @media screen and (max-width: $small-start) {
      height: 38px;
      min-width: 135px;
    }
  }
  &__player-stats-inner {
    padding: 0 20px 0 0;
    width: 100%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      line-height: normal;
    }
    &.is-right {
      justify-self: flex-end;
      border-left: 2px solid #F2F2F2;
      text-align: left;
      justify-content: flex-start;
      padding: 0 0 0 20px;
      @media screen and (max-width: $small-start) {
        padding: 0 0 0 8px;
      }
    }
    @media screen and (max-width: $small-start) {
      font-size: 13px !important;
      padding: 0 10px 0 0;
    }
    >span:nth-child(1) {
      max-width: 60%;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      word-break: break-all;
    }
    >span:nth-child(3) {
      color: $color-accent1;
    }
    img {
      width: 19px;
      margin-top: 1px;
      cursor: pointer;
      @media screen and (max-width: $small-start) {
        width: 15px;
      }
    }
  }
  &__scrollbar {
    margin-top: 30px;
    @media screen and (max-width: $small-start) {
      margin-top: 15px;
    }
  }
}
.football-club-logo {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  @media screen and (max-width: $small-start) {
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
}

.round__button {
  background-color: white;
  background-image: url('../assets/images/arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14%;
  border: solid 2px $color-light-gray;
  width: 30px;
  height: 30px;
  transition: 0.2s;
  @media (hover: hover) {
    &:hover {
      transform: scale(1.2);
    }
  }
  &.hovered-mobile {
    transform: scale(1.2);
  }
  &_next {
    transform: rotate(180deg);
    &.hovered-mobile {
      transform: scale(1.2) rotate(180deg);
    }
    @media (hover: hover) {
      &:hover {
        transform: scale(1.2) rotate(180deg);
      }
    }
  }
  @media screen and (min-width: $medium-start) {
    width: 40px;
    height: 40px;
    background-size: 22%;
    border: solid 3px $color-light-gray;
  }
}

.round_button_hidden {
  width: 30px;
  height: 30px;
  @media screen and (min-width: $medium-start) {
    width: 40px;
    height: 40px;
  }
}

.status {
  display: flex;
  color: $red;
  text-transform: uppercase;
  background-color: $color-light-gray;
  padding: 1px 4px;
  @extend .font-m2;
  @media screen and (min-width: $medium-start) {
    padding: 0 6px;
  }
  @media screen and (max-width: $extra-small-end) {
    font-size: 13px !important;
  }
  &:before {
    margin: 1px 3px 0 0;
    height: 7px;
    width: 7px;
    background-color: #D70024;
    border-radius: 50%;
    content: '';
    display: block;
    align-self: center;
    @media screen and (min-width: $small-start) {
      margin: 1px 4px 0 0;
      height: 8px;
      width: 8px;
    }
  }
}