.success-component {
  @extend .source-sans-pro;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 115%;
  color: #fff;
  min-height: 40px;
  background: #3EC59C;
  border-radius: 10px;
  padding: 0 10px;
  margin-bottom: 10px;
  @media screen and (max-width: $small-start) {
    font-size: 13px;
  }
  >span {
    padding: 10px 0;
  }
  .cross {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 10px 10px 10px 20px;
    margin: -10px;
    justify-self: flex-end;
    cursor: pointer;
  }
  .success {
    padding-right: 10px;
  }
}