.news-row {
    margin-top: 0;
    margin-bottom: 40px;
    @media screen and (max-width: $extra-small-end) {
        margin-left: 0;
        margin-right: 0;
    }
}

.news-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);

    hr {
        margin-top: 30px;
    }
    button, .bottom-info {
        margin-top: 20px;
    }
    .lazy-load-image-background {
        width: 100%;
    }
    img {
        width: 100%;
    }
    hr {
        width: 80px;
        height: 5px;
        background-color: $color-accent1-dark;
        border: none;
    }
    h3 {
        @extend .font-news-header;
        margin-bottom: 0;
    }
    button {
        width: 160px;
        min-width: 160px;
        a {
            color: #fff;
        }
    }
    .bottom-info {
        justify-content: center;
        align-items: center;

        img {
            width: 24px;
            height: 24px;
        }
        span {
            @extend .font-news-bottom-info;
        }
        & > span {
            margin-left: 10px;
        }
    }

    @media screen and (max-width: $medium-end) {
        .lazy-load-image-background {
            width: 75%;
        }
    }
}