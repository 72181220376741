.global-container {
    margin-top: 20px;
}

.global-row {
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $medium-end) {
        flex-direction: column;
    }
    @media screen and (max-width: $extra-small-end) {
        margin-left: 0;
        margin-right: 0;
    }
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-warning {
  display: flex !important;
  align-items: center;
  justify-content: center;
  >div {
    min-width: 340px;
    @media screen and (max-width: $small-end) {
      min-width: 320px;
    }
    width: auto;
    max-width: 90vw;
  }
}

.game-info-modal-wrapper {
  >div {
    @media screen and (min-width: 500px) {
      max-width: 640px !important;
    }
  }

  .modal-wrapper {
    @media screen and (max-width: $small-end) {
      padding: 40px 10px !important;
    }
  }
}

$z_index: (1, 2, 3, 4, 5);

@each $size in $z_index {
  .z-index-#{$size} {
    z-index: $size !important;
  }
}

.global-success {
  opacity: 1;
  background-color: $color-main-text;
  color: $color-accent2;
  position: absolute;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  left: calc(50vw - 50px);
  top: calc(50vh - 25px);
  @extend .bebas-neue;
  @extend .font;
  padding: 15px;
  height: 50px;
  border: 2px solid $color-accent2;
  display: flex;
  align-items: center;
  justify-content: center;
  &.show {
    opacity: 100;
  }
  &.hide {
    opacity: 0;
    z-index: 0;
  }
  &.disabled {
    display: none;
  }
}

.service-text {
  text-align: center;
  @extend .poppins;
  font-weight: 600 !important;
  font-size: 24px;
  line-height: 150%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.service-message {
  width: 100%;
  max-width: 910px;
  @extend .poppins;
  font-weight: 600 !important;
  font-size: 24px;
  line-height: 150%;
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @media screen and (max-width: $medium-start) {
    font-size: 16px;
  }
  img {
    margin-top: 50px;
    margin-bottom: 30px;
    @media screen and (max-width: $medium-start) {
      margin-top: 40px;
    }
  }
  a {
    color: $color-accent1 !important;
  }
  .blue {
    color: $color-accent1 !important;
  }
}

.blue-plank-top-service {
  background-color: $color-accent1;
  height: 4px;
  width: 40px;
  margin-top: 10px;
  margin-bottom: 28px;
  @media screen and (max-width: $medium-start) {
    margin-top: 25px;
    margin-bottom: 20px;
  }
}

.main-logo {
  width: 210px;
  height: 210px;
  @media screen and (max-width: $medium-start) {
    width: 140px;
    height: 140px;
  }
}

.announcement-wrapper {
    background-image: url('../assets/images/background-announcement.svg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50px;
}

.announcement-text {
    color: #fff;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    margin-top: 20px;
    margin-bottom:: 10px;
}

.announcement-badge-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    gap: 10px;
}

.announcement-close {
    position: absolute;
    right: 15px;
    cursor: pointer;
}

.announcement-banner-row {
  margin-bottom: 40px;
}

.announcement-banner-wrapper {
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
    padding-top: 30px;
    padding-bottom: 30px;
}

.announcement-banner-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    margin-left: 50px;
    margin-right: 50px;
    height: 100%;
    @media screen and (max-width: $large-end) {
      margin-left: 25px;
      margin-right: 25px;
    }
    @media screen and (max-width: $small-end) {
        justify-content: flex-start;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 30px;
    }
}

.announcement-banner-text {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 100%;
    font-size: 50px;
    .blue {
        display: inline-block;
        color: $color-accent1 !important;
    }
    @media screen and (max-width: $small-end) {
        text-align: center;
        font-size: 40px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.announcement-banner-badge-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media screen and (max-width: $small-end) {
        gap: 10px;
    }
}

.announcement-banner-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.announcement-banner-img-1 {
    height: 280px;
    margin-right: -30px;
    z-index: 1;
    @media screen and (max-width: $small-end) {
        height: 250px;
    }
}

.announcement-banner-img-2 {
    height: 250px;
    @media screen and (max-width: $large-end) {
      display: none;
    }
    @media screen and (max-width: $small-end) {
      display: block;
      height: 240px;
    }
}

.app-badge {
  height: 50px;
  @media screen and (min-width: $medium-start) and (max-width: $large-end) {
    height: 45px;
  }
}

.app-badge-small {
  height: 40px;
}