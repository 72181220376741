.dropdown {
	transition: 0.8s;
	&__button {
		background-color: rgba(0, 0, 0, 0);
		transition: 0.3s;
		border: none;
	}
	&__icon {
		position: absolute;
		top: 0;
		right: 0;
		display: inline-block;
		transition: 0.3s;
		@media screen and (max-width: $small-end){
			right: -11px;
			img {
				width: 14px;
			}
			&.rotate {
				transform: rotate(180deg);
			}
		}
		&_opened {
			position: absolute;
			top: 0;
			right: 0;
			display: inline-block;
			transition: 0.3s;
			transform: rotate(360deg);
			@media screen and (max-width: $small-end){
				right: -11px;
			}
			&-single {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				transition: 0.3s;
				transform: rotate(180deg);
				@media screen and (max-width: $small-end){
					right: -11px;
					&.rotate {
						transform: none;
					}
					img {
						width: 14px;
					}
				}
			}
		}
	}
}