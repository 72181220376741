.progress-block {
  @extend .progress-bg;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: $small-end) {
      padding: 0 0 40px;
      overflow: hidden;
      width: 100%;
    }
  }
  &__wrapper {
    &_isNextSeason {
      left: 0 !important;
      max-width: 220px;
      @media screen and (min-width: $medium-start) {
        max-width: 420px;
      }
    }
    position: relative;
    max-width: 823px;
    width: 100%;
    &_step1, &_step2 {
      @media screen and (max-width: $small-end) {
        left: calc(15% + -14px);
      }
    }
    &_step3 {
      @media screen and (max-width: $small-end) {
        right: calc(50% - 14px);
      }
    }
  }
  &_height {
    min-height: 200px;
    @media screen and (min-width: $medium-start) {
      min-height: 330px;
    }
  }
  &_paddings {
    margin-bottom: 30px;
    padding-top: 20px;
    @media screen and (min-width: $medium-start) {
      padding-top: 50px;
    }
  }
  .title {
    &__short-line {
      @media screen and (max-width: $small-end) {
        width: 30px;
        height: 3px;
      }
    }
    &__text {
      @media screen and (max-width: $small-end) {
        font-size: 30px;
        text-align: center;
      }
    }
  }
  .progress {
    height: 2px;
    @media screen and (min-width: $medium-start) {
      height: 4px;
    }
    background-color: white;
    border-radius: 0;
    &_mt {
      margin-top: 34px;
      @media screen and (min-width: $medium-start) {
        margin-top: 51px;
      }
    }
    .progress-bar {
      background-color: $color-accent2;
    }
  }
}

.step {
  display: inline-block;
  transition: 0.6s ease;
  padding: 0 11px;
  position: absolute;
  bottom: -14px;
  @media screen and (min-width: $medium-start) {
    padding: 15px 23px 0 24px;
    bottom: -20px;
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 52px;
    height: 30px;
    background: url('../assets/images/progress-step-bg-sm.svg') no-repeat;
    @media screen and (min-width: $medium-start) {
      width: 87px;
      height: 55px;
      background: url('../assets/images/progress-step-bg.svg') no-repeat;
    }
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    @extend .font-h6;
    width: 30px;
    height: 30px;
    background-color: $color-accent2;
    @media screen and (min-width: $medium-start) {
      height: 40px;
      width: 40px;
      font-size: 24px;
    }
  }

  &_1 {
    left: 0;
    margin-left: -12px;
    @media screen and (min-width: $medium-start) {
      margin-left: -34px;
    }
  }
  &_2 {
    left: 50%;
    margin-left: -26px;
    @media screen and (min-width: $medium-start) {
      margin-left: -44px;
    }
    &_isNextSeason {
      left: 95%;
      @media screen and (min-width: $medium-start) {
        left: 100%;
        margin-left: -62px;
      }
    }
  }
  &_3 {
    left: 100%;
    margin-left: -40px;
    @media screen and (min-width: $medium-start) {
      margin-left: -62px;
    }
  }
  &__text {
    color: white;
    @extend .font-m2;
    white-space: nowrap;
    position: absolute;
    top: 36px;
    @media screen and (min-width: $medium-start) {
      top: 65px;
      font-size: 20px !important;
    }
    &_pos1 {
      left: -24px;
      @media screen and (min-width: $medium-start) {
        left: -180%;
      }
    }
    &_pos2 {
      left: -165%;
      @media screen and (min-width: $medium-start) {
        left: -250%;
      }
    }
    &_pos3 {
      left: -165%;
      @media screen and (min-width: $medium-start) {
        left: -200%;
      }
    }
  }
  &__upcoming {
    top: 24px;
    @media screen and (min-width: $medium-start) {
      top: 41px;
    }
    width: 24px;
    height: 24px;
    background-color: white;
    position: absolute;
    margin-left: -1px;

    &_taken {
      background-color: $color-accent2;
      background-image: url('../assets/images/checked.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
    &_2 {
      left: 50%;
      margin-left: -12px;
    }
    &_3 {
      right: 0;
      margin-right: -1px;
    }
  }
}

@media (min-width: $medium-start) and (max-width: 890px) {
  .progress-block {
    overflow: hidden;
  }
  .step__text_pos1 {
    position: relative;
    left: 0;
  }
  .step__text_pos3 {
    position: relative;
    left: -96px;
  }
}
@media (min-width: $large-start) and (max-width: 1030px) {
  .progress-block {
    overflow: hidden;
  }
  .step__text_pos1 {
    position: relative;
    left: 0;
  }
  .step__text_pos3 {
    position: relative;
    left: -96px;
  }
}
@media (min-width: 1440px) {
  .progress-block {
    background-size: 100%;
  }
}
