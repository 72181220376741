.ad-unit-billboard {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 40px;
}

.ad-unit-rectangle {
  @media screen and (max-width: $medium-end) {
    margin-bottom: 40px;
    order: -1;
  }
}