@import './variables';
@import './fonts';

.schedule-item {
	border: 2px solid $color-light-gray;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 20px;
	@media (min-width: $medium-start) {
		margin-bottom: 30px;
	}
	&:last-of-type {
	    margin-bottom: 0;
	}
	&_opened {
		border: 2px solid $color-accent1;
	}
	&__header {
		padding: 0 15px;
		min-height: 36px;
		@media (min-width: $medium-start) {
			padding: 20px;
			min-height: 63px;
		}
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0;
		&_opened {
			background-color: $color-accent1;
			background-image: url('../assets/images/board-bg.svg');
			color: white;
		}
		&_hover {
			transition: 0.3s;
			&:hover {
				box-shadow: 0 0 5px $color-accent2;
			  cursor: pointer;
			}
		  }
	}
	&__title {
		@extend .font-m1;
		@media screen and (max-width: $small-end) {
			font-size: 15px;
		}
	}
	&__status-info {
		position: relative;
		padding-right: 24px;
		text-transform: capitalize;
		@extend .font-m4;
		@media screen and (min-width: $medium-start) {
			font-size: 15px;
			padding-right: 28px;
		}
		&_opened {
			color: white;
		}
		&_relative {
			position: relative;
		}
	}
	&__content {
		overflow: hidden;
	}
}

.deadline {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: relative;
	top: 1px;
	.title {
		color: $color-secondary-text;
		@extend .font-m4;
		font-size: 9px;
		@media screen and (min-width: $medium-start) {
			font-size: 12px;
		}
		margin-bottom: 3px;
		&_opened {
			color: $color-d1;
		}
	}
	.date {
		@extend .font-m4;
		@media screen and (min-width: $medium-start) {
			font-size: 15px;
		}
		margin-bottom: 0;
		text-transform: capitalize;
	}
	&_mt {
		margin-top: -10px;
		position: relative;
		top: 5px;
	}
}