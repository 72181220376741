.bg-sign-up-block-layer-2 {
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: $color-accent1;
  background-image: url('../assets/images/bg-sign-up-block.svg');
}

.bg-soccer-player2 {
  background-image: url('../assets/images/cta-football-player.png');
  // background-position: right bottom; // md
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-black {
  background-color: black;
  background-position: right bottom;
}

.bg-accent1 {
  background-color: $color-accent1;
}

.bg-menu-item {
  background-size: contain;
  background-position: 50% 0;
  background-repeat: no-repeat;
}

.bg-menu-item1 {
  @extend .bg-menu-item;
  background-image: url('../assets/images/menu-build-lineup.png');
}
.bg-menu-item2 {
  @extend .bg-menu-item;
  background-image: url('../assets/images/menu-join-us.png');
}
.bg-menu-item3 {
  @extend .bg-menu-item;
  background-image: url('../assets/images/menu-prizes.png');
}

.bg-contact-us-blue {
  background-color: $color-accent1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-image: url('../assets/images/shape-contact-us.svg');
}

.bg-fill-blue {
  background-color: $color-accent1;
}

.progress-bg {
  background-image: url('../assets/images/progress-bg.svg');
  @media screen and (max-width: $small-end) {
    background-image: url('../assets/images/progress-bg-sm.svg');
    background-size: cover;
  }
  background-repeat: no-repeat;
  @extend .bg-fill-blue;
}

.bg-welcome {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: $color-accent1;
  background-image: url('../assets/images/bg-welcome.svg');
  background-size: auto 100%;
  @media (max-width: $small-end) {
    background-image: url('../assets/images/bg-welcome-sm.svg');
  }
  @media (min-width: 1880px) {
    background-size: 100%;
  }
}
