@import './variables';
@import './fonts';

.button {
  padding: 14px;
  width: 100%;
  text-align: center;
  border-radius: 0;
  border: 2px solid;
  transition: ease 0.5s all;
  @media screen and (max-width: $small-end) {
    width: 140px !important;
    min-width: 140px !important;
    height: 41px !important;
    min-height: 41px !important;
    padding: 9.5px 3px !important;

    &.single {
      height: 50px !important;
      min-height: 50px !important;
      width: 450px !important;
      min-width: 450px !important;
    }

    &.double {
      height: 50px !important;
      min-height: 50px !important;
      width: 250px !important;
      min-width: 250px !important;
    }
  }
  @media screen and (max-width: $extra-small-end) {
    &.single {
      width: 77.5vw !important;
      min-width: 77.5vw !important;
    }
    &.double {
      width: 42.5vw !important;
      min-width: 42.5vw !important;
    }
  }
  &.mobile-bordered {
    @media screen and (max-width: $small-end) {
      border: 2px solid white;
    }
  }
  &.activeBtn {
    @media screen and (max-width: $medium-end) {
      outline: none;
      color: $color-accent2;
      border-color: $color-accent2;
      background-color: $color-main-text;
    }
  }
  &:hover {
    @media (hover: hover) {
      color: $color-accent2;
      border-color: $color-accent2;
      background-color: $color-main-text;
    }
  }
  &:focus {
    outline: none;
    color: $color-accent2;
    border-color: $color-accent2;
    background-color: $color-main-text;
  }
  &:active {
    transition: 0.2s ease;
    transform: translateY(2px);
    filter: saturate(150%);
 }
  &_large {
    @extend .font-btn;
    min-width: 260px;
  }
  &_medium {
    @extend .font-btn;
    min-width: 150px;
    width: 150px;
    @media screen and (min-width: $medium-start) {
      width: 160px;
      min-width: 160px;
    }
  }
  &_small {
    @extend .font-m2;
    text-transform: capitalize;
    padding: 9.5px;
    min-width: 90px;
  }
  &_blue {
    border-color: $color-accent1;
    background-color: $color-accent1;
    color: white;
  }
  &_white {
    border-color: $color-accent1;
    background-color: white;
    color: $color-accent1;
  }
  &_red {
    border-color: $red;
    background: $red;
    color: white;
  }
  &_disabled {
    opacity: 0.25;
    pointer-events: none;
    &:hover {
      cursor: unset;
    }
  }
  &.finish-register-btn {
    margin-top: 40px;
    width: 160px;
    @media screen and (min-width: $medium-start) {
      margin-top: 50px;
      width: 260px;
    }
  }
  &.limited-height {
    height: 50px;
    max-height: 50px;
    @media screen and (max-width: $small-end) {
      height: 41px;
      max-height: 41px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.hidable-when-disabled.button_disabled {
  opacity: 0;
}

.game-item {
  button:focus {
    border: unset !important;
    outline: none;
  }
}

.hidden-button {
  opacity: 0;
}