.boards-row {
    margin-top: 20px;
    margin-bottom: 40px;
    .col >div:not(:last-child) {
        margin-bottom: 40px;
    }
    @media screen and (max-width: $medium-end) {
        >.col:not(:last-child) {
            margin-bottom: 40px;
        }
    }
}