.rules {
  &__container {
    padding: 0 10px;
  }
  // need to pass as props in to <Select />
  .css-1oscnvr-placeholder,
  .css-1uccc91-singleValue {
    @extend .font-m3;
    font-weight: 600;
  }
  .section-nav {
    &_indents {
      margin-top: 39px;
    }
    &__title {
      @extend .font-h4new;
    }
    &__list {
      list-style: none;
      margin-bottom: 0;
    }
    &__item {
      cursor: pointer;
      @extend .font-m2;
      &_active-link {
        color: $color-accent1;
      }
      a {
        transition: ease 0.3s;
        &:hover,
        &:focus {
          color: $color-accent1;
          outline: none;
        }
      }
    }
  }
  .section {
    &_indents {
      margin: 0 0 40px;
      padding: 0 20px;
      @media (min-width: $medium-start) {
        padding: 0;
      }
    }
    &__icon {
      width: 64px;
      height: 40px;
      background-image: url('../assets/images/m-bullet-background-small.svg');
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 12px 0 20px;
      .number {
        color: white;
        @extend .font-h6;
        display: flex;
        justify-content: center;
      }
      @media (min-width: $medium-start) {
        width: 104px;
        height: 70px;
        background-image: url('../assets/images/bullet-background-large.svg');
        padding: 15px 18px 0 30px;
        .number {
          font-size: 30px;
        }
      }
      &_position {
        position: relative;
        right: 20px;
        @media (min-width: $medium-start) {
          right: 30px;
        }
      }
    }
    &__title {
      @extend .font-h4; // H5
      @media (min-width: $medium-start) {
        font-size: 36px;
      }
      &_mt {
        margin-top: 21px;
      }
    }
    &__text {
      @extend .font-rules-and-faq;
      &_m {
        margin: 11px 0 22px;
      }
    }
    &__image {
      height: 100%;
      max-width: 100%;
      object-fit: contain;
      object-position: center top;
    }
    &__image-wrapper {
      width: 100%;
      height: 250px;
      &_indents {
        margin-bottom: 30px;
        @media (min-width: $large-start) {
          margin-top: 91px;
        }
      }
    }
  }
  .rules-tabs {
    border: none;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    &__wrapper {
      display: flex;
      justify-content: space-between;
    }
    &__item {
      margin: 0 5px;
      width: 90px;
      @media (min-width: $medium-start) {
        width: 140px;
      }
      cursor: pointer;
      &:hover {
        outline: none;
      }

      a {
        color: black;
        &:hover {
          color: $color-accent2;
          border-bottom: solid 1px $color-accent1;
          @media (min-width: $medium-start) {
            border-bottom: solid 2px $color-accent1;
          }
        }
      }

      .active {
        color: $color-accent1;
        border-bottom: solid 1px $color-accent1;
        @media (min-width: $medium-start) {
          border-bottom: solid 2px $color-accent1;
        }
      }
    }
    &__link {
      color: #000;
      border: none;
      @extend .font-h7;
      padding: 7px;
      padding-bottom: 5px;
      text-align: center;
      @media (min-width: $medium-start) {
        font-size: 24px;
        padding-bottom: 11px;
      }
    }
  }
  .tab-content {
    margin-top: 20px;
    @media (min-width: $medium-start) {
      margin-top: 40px;
    }
  }

  #autoscroll-table {
    overflow-x: auto !important;
  }
}

.faq-item {
  &_mb {
    margin-bottom: 20px;
  }
  &__question {
    padding: 20px 30px 20px 15px;
    .dropdown__icon {
      top: initial;
      right: 16px;
      &_opened {
        top: inherit;
        right: 16px;
      }
    }
  }
  &__question-title {
    @extend .font-rules-and-faq;
    color: inherit;
  }
  &__answer {
    @extend .font-rules-and-faq;
    font-weight: 300 !important;
    padding: 20px;
  }
}

