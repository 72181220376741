.football-field-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.football-field-container-small {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 98%;
  margin-bottom: 180px;
  @media screen and (max-width: $medium-end) {
    margin-top: 20px;
  }
  @media screen and (max-width: $small-end) {
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 150px;
  }
}

.football-field-image {
  width: 100%;
  height: 100%;
  @media screen and (max-width: $small-end) {
    top: 0;
    max-height: 580px;
    z-index: -1;
    background-color: rgb(203, 225, 242);
    box-shadow: 0 100px 150px rgb(175, 210, 235);
  }
  @media screen and (max-width: 360px) {
    width: auto;
  }
}

.football-field-image-empty  {
  position: absolute;
  height: 34%;
  @media screen and (max-width: $small-end) {
    height: 25%;
  }
}

.football-field-image-empty-text {
  position: absolute;
  @extend .poppins;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: white;
  padding: 0 20px;
  width: 90%;
  @media screen and (max-width: $small-end) {
    max-width: 460px;
  }
}

.football-field-wrapper {
  width: 100%;
  height: 88%;
  position: absolute;
  top: 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: $medium-end) {
    height: 84%;
    top: 40px;
  }
  @media screen and (max-width: $small-end) {
    height: 495px;
    top: 50px;
  }
  @media screen and (max-width: 500px) {
    height: 88%;
    top: 40px;
  }
}

.football-field-bench-wrapper {
  position: absolute;
  width: 100%;
  justify-content: center;
  display: grid;
  bottom: -130px;
  grid-template-columns: 30% 20% 20% 20%;
  >div:not(:first-child) {
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: $medium-end) {
    bottom: -114px;
  }
  @media screen and (max-width: $small-end) {
    bottom: -114px;
    grid-template-columns: 150px 100px 100px 100px;
  }
  @media screen and (max-width: 500px) {
    bottom: -21%;
    grid-template-columns: 28% 20% 20% 20%;
  }
}

.forwards-line {
  height: 109px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  @media screen and (max-width: $medium-end) {
    height: 75px;
  }
  @media screen and (max-width: $small-end) {
    height: 90px;
  }
}

.football-player-wrapper {
  z-index: 2;
  width: 100px;
  height: 109px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $medium-end) {
    flex-direction: column;
    width: 60px;
    height: 75px;
  }
  &.line-2 {
    margin: 0 40px;
    @media screen and (max-width: $small-end) {
      margin: 0 50px;
    }
    @media screen and (max-width: 500px) {
      margin: 0 7.5vw;
    }
    @media screen and (max-width: $minimal-end) {
      margin: 0 5vw;
    }
  }
  &.line-3 {
    margin: 0 30px;
    @media screen and (max-width: $small-end) {
      margin: 0 40px;
    }
    @media screen and (max-width: 500px) {
      margin: 0 6vw;
    }
    @media screen and (max-width: $minimal-end) {
      margin: 0 4vw;
    }
  }
  &.line-4 {
    margin: 0 20px;
    @media screen and (max-width: $small-end) {
      margin: 0 30px;
    }
    @media screen and (max-width: 500px) {
      margin: 0 4.5vw;
    }
    @media screen and (max-width: $minimal-end) {
      margin: 0 3vw;
    }
  }
  &.line-5 {
    margin: 0 15px;
    @media screen and (max-width: 1200px) {
      margin: 0 1.4vw;
    }
    @media screen and (max-width: 1100px) {
      margin: 0 1.0vw;
    }
    @media screen and (max-width: 1050px) {
      margin: 0 0.6vw;
    }
    @media screen and (max-width: $medium-end) {
      margin: 0 1.6vw;
    }
    @media screen and (max-width: $small-end) {
      margin: 0 20px;
    }
    @media screen and (max-width: 500px) {
      margin: 0 3.6vw;
    }
    @media screen and (max-width: 450px) {
      margin: 0 2.6vw;
    }
    @media screen and (max-width: 400px) {
      margin: 0 1.8vw;
    }
    @media screen and (max-width: $minimal-end) {
      margin: 0 4px;
    }
    @media screen and (max-width: 340px) {
      margin: 0 2px;
    }
  }
}

.football-player-wrapper:hover {
  z-index: 5;
}

.existed-player {
  cursor: pointer;
  z-index: 100;
}

.football-player-image {
  position: absolute;
  width: 74px;
  height: 90px;
  @media screen and (max-width: $medium-end) {
    width: 51px;
    height: 63px;
  }
}

.football-player-add-image {
  display: none;
  @media screen and (max-width: $small-end) {
    position: absolute;
    top: 34px;
    display: block;
    width: 14px;
    height: 14px;
  }
}

.player-type-title {
  text-transform: uppercase;
  z-index: 100;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  @media screen and (max-width: $medium-end) {
    font-size: 12px;
  }
  @media screen and (max-width: $small-end) {
    position: absolute;
    top: 20px;
    font-size: 11px;
  }
}

.football-player-controls {
  z-index: 5;
  width: 82px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 27px;
  @media screen and (max-width: $medium-end) {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
  }
}

.football-player-shirt {
  bottom: 0;
  position: absolute;
  max-width: 51px;
  max-height: 63px;
  min-width: 51px;
  min-height: 63px;
  margin-bottom: 46px;
  @media screen and (max-width: $medium-end) {
    position: relative;
    top: 0;
    max-width: 34px;
    max-height: 42px;
    min-width: 34px;
    min-height: 42px;
    margin-bottom: 2px;
  }
}

.football-player-info {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
  @media screen and (max-width: $medium-end) {
    position: relative;
    top: 0;
    height: unset;
  }
}

.football-player-info-side-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 18px;
  @media screen and (max-width: $medium-end) {
    position: absolute;
    top: -11px;
    height: 11px;
  }
}

.football-player-info-side-item {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  background: $color-accent3;
  border-radius: 4px;
  width: 30px;
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media screen and (max-width: $medium-end) {
    width: 18px;
    font-size: 9px;
  }
}

.football-player-info-name {
  height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
  background: #FFFFFF;
  @media screen and (max-width: $medium-end) {
    height: 16px;
  }
}

.player-info-name-text {
  max-width: 93px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  @media screen and (max-width: $medium-end) {
    max-width: 53px;
    font-size: 11px;
  }
}

.football-player-info-bottom-wrapper {
  display: flex;
  width: fit-content;
  min-width: 100%;
  height: auto;
  justify-content: center;
  align-self: center;
  @media screen and (max-width: $small-end) {
    min-height: 15px;
  }
}

.football-player-info-bottom {
  padding: 3.5px 5px;
  font-size: 13px;
  min-width: 6px;
  width: 100%;
  background: #4CB1E3;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  font-family: Source Sans Pro, sans-serif;
  height: auto;
  @media screen and (max-width: $medium-end) {
    padding: 0.5px 3px;
    font-size: 10px;
  }
  @media screen and (max-width: 400px) {
    font-size: 9px;
  }
  .abbreviation {
    height: auto;
    overflow: hidden;
    text-overflow: clip;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    @media screen and (max-width: $medium-end) {
      span {
        line-height: 10px;
      }
    }
    span {
      line-height: 13px;
    }
    @media screen and (max-width: $small-end) {
      flex-direction: column;
    }
  }

}

.player-controls-item {
  z-index: 120;
  cursor: pointer;
  @media screen and (max-width: $medium-end) {
    width: 30px;
    height: 30px;
  }
}

.football-field-top {
  position: absolute;
  width: 60%;
  top: -40px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  @media screen and (max-width: $medium-end) {
    top: -30px;
    >div, img {
      width: 40px;
      height: 40px;
    }
    >div {
      &.captain-wrapper {
        width: 95px;
      }
    }
    >.football-field-format {
      padding-top: 8px;
      margin-left: 0;
    }
  }
  @media screen and (max-width: $small-end) {
    top: -28px;
    width: 88%;
    max-width: 440px;
  }
}

.football-field-format {
  cursor: pointer;
  padding: 11px;
  width: 50px;
  height: 50px;
  background: #ECC435;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 9;
  .dropdown-formations {
    position: absolute;
    top: 52px;
    width: 80px;
    @media screen and (max-width: $small-end) {
      top: 44px;
      right: 0;
    }
    >div:first-child {
      border-top: 2px solid #FFFFFF;
    }
    >div:last-child {
      border-bottom: 2px solid #FFFFFF;
    }
    >div:hover {
      background-color: $color-main-text;
      color: $color-accent2;
    }
    >div {
      @extend .source-sans-pro;
      background-color: $color-accent2;
      border: 2px solid #FFFFFF;
      border-top: 1px solid #FFFFFF;
      border-bottom: 1px solid #FFFFFF;
      height: 36px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      cursor: pointer;
      z-index: 600;
      &.selected-format {
        background-color: $color-main-text;
        color: $color-accent2;
      }
    }
  }
}

.football-field-format-line {
  margin-right: -4px;
  margin-bottom: 4px;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: row;
}

.football-field-format-item {
  background-color: white;
  margin-right: 4px;
  width: 4px;
  height: 4px;
  @media screen and (max-width: $medium-end) {
    margin-right: 3px;
    width: 3px;
    height: 3px;
  }
}

.modal-dialog {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wrapper {
  position: relative;
  background: #FFFFFF;
  border: 2px solid #008FD7 !important;
  box-sizing: border-box;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.35);
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  padding: 50px 40px;
  @media screen and (max-width: $small-end) {
    padding: 40px 30px;
  }
  .success-component {
    margin-bottom: 20px;
    text-align: center;
  }

  .fantasy-modal-title, .fantasy-modal-error-msg, .fantasy-modal-subtitle {
    margin-bottom: 20px;
  }

  input, .fantasy-modal-input, .fantasy-modal-error-msg, .fantasy-modal-subtitle {
    width: 260px;
  }

  &.modal-content {
    width: 344px;
    @media screen and (max-width: $small-end) {
      width: 324px;
    }
    &.player-info-modal, &.confirm-modal-content {
      width: auto;
    }
    &.game-info-modal {
      width: 640px;
    }
  }

  &.confirm-modal-content {
    max-height: 95vh;
    overflow: hidden auto;
    @media screen and (max-width: $small-end) {
      .font-h3 {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }
}

.modal-close {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 30px;
  @media screen and (max-width: $small-end) {
    width: 35px;
  }
  cursor: pointer;
}

.modal-content {
  flex-direction: column;
  justify-content: space-between;
}

.fantasy-modal-title {
  font-family: Bebas Neue, cursive;
  font-style: normal;
  font-size: 30px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  justify-content: center;
  margin-bottom: 20px;
  @media screen and (min-width: $medium-start) {
    font-size: 36px;
  }
}

.fantasy-modal-subtitle {
  @extend .source-sans-pro;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 125%;
  text-align: center;
  color: $color-main-text;
  @media screen and (max-width: $small-end) {
    font-size: 15px;
  }
}

.fantasy-modal-input {
  width: 260px;
  height: 36px;
  border-top: none;
  border-left: none;
  border-right: none;
  background-image: none;
  background-color: transparent !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-bottom: 20px;
  line-height: normal;

  &.error {
    border-bottom: 2px solid $red;

    .css-wh54hb-control {
      border-bottom: 2px solid $red;
    }
  }
}

.fantasy-modal-input:focus, input:focus{
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.2);
}

.fantasy-modal-input::placeholder {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: normal;
  color: $color-d1;
}

.signin-bottom {
  height: 50px;
}

.signin-button {
  position: relative;
  margin-bottom: 20px;
  background: #008FD7;
  width: 161px;
  height: 53px;
  font-family: Bebas Neue, cursive;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
}

.signin-button:hover {
  color: black;
}

.modal-bottom-link {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  text-align: right;
  color: #008FD7;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
}

.fantasy-modal-error {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  color: red;
  margin-top: -17px;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
}

.button-spinner {
  position: absolute;
  bottom: 5px;
}

.bench-label {
  bottom: -25px;
}

.captain-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  @media screen and (max-width: $large-end) {
    width: 16px;
    height: 16px;
  }
  @media screen and (max-width: $medium-end) {
    max-width: 11px !important;
    max-height: 11px !important;
    width: calc(10px + 0.2vw);
    height: calc(10px + 0.2vw);
  }
}

.captain-title {
  position: absolute;
  background-color: black;
  color: white;
  z-index: 999;
  padding: 3px;
  @extend .source-sans-pro;
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
  border-radius: 5px;
  text-align: center;
  min-width: 200px;
  right: -150%;
  @media screen and (max-width: $small-start) {
    left: -60%;
  }
}

.vice-captain-img {
  margin-left: 15px;
  @media screen and (max-width: $medium-end) {
    margin-left: 10px;
  }
}

.bg-color-red {
  background-color: #C40000;
}

.bg-color-yellow {
  background-color: #D8D800;
}