.prizes {
    &__places-list {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .place-item {
        margin-top: 20px;
        width: 100%;
        &_other-places {
            @media screen and (min-width: $medium-start) {
                width: 46.5%;
                margin-top: 40px;
            }
        }
        .row {
            margin-right: -48px;
            margin-left: -30px;
        }
        .main-image-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 300px;
            width: 100%;
            max-width: 370px;
            @media screen and (min-width: $medium-start) {
                height: 100%;
                min-height: 500px;
                max-width: initial;
            }
            &__image {
                max-width: 100%;
                max-height: 100%;
                position: absolute;
                z-index: 1;
            }
            &__shape-1 {
                position: absolute;
                height: 100px;
                left: 70px;
                bottom: 56px;
                border-left: 4px solid $color-accent1;
                transform: skew(-45deg);
                @media screen and (min-width: $medium-start) {
                    height: 150px;
                    left: 146px;
                    bottom: 139px;
                }
            }
            &__shape-2 {
                position: absolute;
                width: 36%;
                height: 45%;
                right: 17%;
                top: 0;
                background: $color-accent2;
                transform: skew(-44deg);
            }
            &__shape-3 {
                position: absolute;
                width: 42%;
                height: 65%;
                left: 28%;
                bottom: 0;
                background: linear-gradient(180deg, rgba(0, 143, 215, 0) 0%, $color-accent1 100%);
                background-blend-mode: multiply;
                transform: skew(-44deg);
            }
            &__shape-4 {
                position: absolute;
                width: 50px;
                height: 50px;
                right: 0;
                bottom: 76px;
                border-left: 4px solid $color-accent2;
                transform: skew(-45deg);
                @media screen and (min-width: $medium-start) {
                    height: 90px;
                    right: 8%;
                    bottom: 29%;
                }
            }
            &__shape-5 {
                position: absolute;
                height: 150px;
                bottom: -22px;
                border-left: 5px solid $color-accent2;
                transform: rotate(45deg);
                right: 51px;
                @media screen and (min-width: $medium-start) {
                    height: 290px;
                    right: 20%;
                    bottom: -8%;
                    border-width: 10px;
                }
            }
        }
        &__image {
            width: 100%;
            min-height: 250px;
            max-height: 250px;
            object-fit: contain;
            @media screen and (min-width: $medium-start) {
                min-height: 300px;
                max-height: 300px;
            }
        }
        &__title {
            @extend .font-h3;
            &_first-place {
                text-align: center;
                font-size: 48px !important;
                @media screen and (min-width: $large-start) {
                    font-size: 72px !important;
                    text-align: start;
                }
            }
            padding: 0 20px;
            margin-top: 40px;
            .blue {
                color: $color-accent1;
            }
        }
        &__list {
            list-style: none;
        }
        &__list-item {
            padding: 15px 22px 5px 73px;
            margin-top: 20px;
            background: url('../assets/images/checkmark-small.svg') no-repeat;
            @extend .font-h6;
            @media screen and (min-width: $large-start) {
                padding: 11px 28px 5px 80px;
                font-size: 24px !important;
                margin-top: 30px;
            }
            &_first-place {
                margin-top: 40px;
                padding: 105px 15px 0;
                background: url('../assets/images/checkmark-large.svg') no-repeat center top;
                text-align: center;
                font-size: 24px !important;
                @media screen and (min-width: $large-start) {
                    margin-top: 31px;
                    padding: 90px 0 0 30px;
                    font-size: 36px !important;
                    text-align: initial;
                    background-position: initial;
                }
            }
        }
    }
}