.top--110px {
  top: -110px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-30 {
  bottom: 30px;
}

.right-0 {
  right: 0;
}

$zi: (0, 1 ,2 ,3 ,4 ,5);

@each $layer in $zi {
  .z-index-#{$layer} {
    z-index: $layer;
  }
}

$basis_sizes: (30, 44, 46, 48, 50);

@each $size in $basis_sizes {
  .flex-basis-#{$size} {
    flex-basis: $size + 0%;
  }
}