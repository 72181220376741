.home-container {
    overflow-anchor: none;
}

.main-screen {
	position: relative;
	padding-right: 0;
	padding-left: 0;
	margin-bottom: 80px;
    @media screen and (max-width: $small-end) {
        margin-bottom: 40px;
    }
	
	@media screen and (max-width: $small-end) {
		padding-right: 10px;
	    padding-left: 10px;
	}

	&__content {
		max-width: 415px;
		margin-top: 40px;
		@media screen and (max-width: $small-end) {
			min-width: 175px;
			margin-top: 20px;
		}
		h1 {
			margin-bottom: 20px;
			@media screen and (min-width: $medium-start) {
				margin-bottom: 25px;
			}
		}
		.short-line {
			margin-bottom: 0;
		}
		.text {
			@extend .font-12-20;
			@extend .color-main-text;
			margin-top: 20px;
			@media screen and (min-width: $medium-start) {
				margin-top: 25px;
			}

			@media screen and (max-width: $small-end) {
				margin-right: 10px;
			}
		}
		.sign-in-up-buttons {
			margin-top: 20px;
			@media screen and (min-width: $medium-start) {
				margin-top: 25px;
				width: 105%;
			}
		    >div {
                display: flex;
                @media screen and (max-width: $small-end) {
                    flex-direction: column;
                }
			}
			.button {
				min-width: 70px;
				width: 90px;
				padding: 9px 0;
				@media screen and (min-width: $medium-start) {
					width: 160px;
					padding: 14px 0;
				}
			}
			a .button {
				margin-left: 20px;
				@media screen and (max-width: $small-end) {
					margin-left: 0;
					margin-top: 10px;
				}
				@media screen and (min-width: $medium-start) {
					margin-left: 35px;
				}
			}
		}
	}

	&__banners {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding-left: 30px;
		gap: 15px;
		max-width: 500px;

		@media screen and (min-width: $extra-large) {
			margin-right: 100px;
		}
		.row {
			text-align: center;
			margin: 0;
			padding: 0;
			max-width: 100%;
			.item {
				width: 192px;
			}
			&.number_1 {
				padding-left: 20px;

				.img {
					background-image: url('../assets/images/banner-1.png');
				}
			}
			&.number_2 {
				justify-content: flex-end;

				.img {
					background-image: url('../assets/images/banner-2.png');
				}
			}

			&.number_3 {
				.img {
					background-image: url('../assets/images/banner-3.png');
				}
			}

			.img {
				width: 100%;
				height: 108px;
				background-size: 100% auto;
				background-position: center;
				background-repeat: no-repeat;
				margin-bottom: 5px;
			}

			div {
				font-size: 30px;
				font-family: Bebas Neue, cursive;
				color: #000;
				line-height: 115%;
			}
		}

		@media screen and (max-width: $small-end) {
			padding-left: 0;
			gap: 25px;
			.row {
			    &.number_1 {
			        padding-left: 10px;
			    }
				.item {
					width: 96px;
				}
				.img {
					height: 54px;
				}
				div {
					font-size: 16px;
				}
			}
		}
	}

	&__scroll-image {
		margin-top: auto;
		width: 10px;
	}
}

.dream-team {
	@media screen and (max-width: $small-end) {
		max-width: 100vw;
	}
	.football-field-container-small {
        margin-bottom: 80px;
    }
    .football-player-info-bottom {
        @media screen and (min-width: $medium-start) {
            padding: 0.5px 0;
        }
    }
	&__spinner {
		position: absolute;
		top: 27%;
		@media screen and (min-width: $medium-start) {
			left: 4px;
		}
	}
	&__tab-list {
		@extend .font-h7;
		@media screen and (min-width: $medium-start) {
			margin-top: 26px;
			font-size: 24px !important;
		}
		@media screen and (max-width: $small-end) {
		    margin-top: 16px;
			font-size: 18px !important;
		}
		.nav-item {
			color: black;
			text-transform: uppercase;
			cursor: pointer;
		}
		.nav-tabs {
			border-bottom: none;
			.nav-link {
			position: relative;
			border: none;
			color: black;
			padding: 5px 14px;
			border-bottom: solid 2px transparent;
			@media screen and (min-width: $medium-start) {
				padding: 5px 21px 1px;
			}
			transition: 0.2s;
			&:hover {
				color: $color-accent1;
			}
			&.active {
				color: $color-accent1;
				border: none;
				cursor: default;
				border-bottom: solid 2px $color-accent1;
			}
			}
		}
	}
	.switch-round {
		@media screen and (min-width: $medium-start) {
			margin-bottom: 20px;
		}
	}
}

.switch-round {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
	&__title {
		@extend .font-h7;
		font-size: 20px !important;
		margin: 0 16px;
	}
	@media screen and (min-width: $medium-start) {
		margin-top: 31px;
		&__title {
			font-size: 24px !important;
			margin: 0 35px;
		}
	}
}

.sign-up-block {
	@media screen and (min-width: $medium-start) {
		position: relative;
		height: 220px;
		padding-top: 66px;
		display: flex;
		align-items: center;
	}
	&__image {
		height: 155px;
		position: absolute;
		bottom: 0;
		overflow: hidden;
		width: 100%;
		background-color: $color-accent1;
	}

	&__image-wrapper {
		position: relative;
		height: 220px;
		width: 100%;
		@media screen and (min-width: $medium-start) {
			position: absolute;
			bottom: 0;
		}
	}

	.image {
		&__8 {
			@extend .bg-soccer-player2;
			height: 100%;
			max-height: 100%;
			z-index: 2;
			position: absolute;
			left: 20%;
			right: 20%;
			@media screen and (min-width: $medium-start) {
				right: -36.46%;
			}
		}
		&__7 {
			/* line */
			position: absolute;
			z-index: 1;
			left: 70%;
			top: 7%;
			bottom: 27%;
			border: 2px solid #008FD7;
			transform: rotate(45deg);
			@media screen and (min-width: $medium-start) {
				left: 82%;
				top: 0;
			}
		}
		&__1 {
			position: absolute;
			left: -25%;
			right: 102%;
			top: -200%;
			bottom: -155%;
			background: linear-gradient(189.82deg, #FFFFFF 9.32%, rgba(255, 255, 255, 0) 73.95%);
			opacity: 0.1;
			transform: rotate(45deg);
			@media screen and (min-width: $medium-start) {
				right: 95%;
			}
		}
		&__2 {
			// box-shadow: 0 0 1px 4px red;
			position: absolute;
			left: -20%;
			right: 76%;
			top: -203%;
			bottom: -132%;
			background: linear-gradient(188.03deg, #FFFFFF 9.32%, rgba(255, 255, 255, 0) 73.95%);
			opacity: 0.2;
			transform: rotate(-135deg);
			@media screen and (min-width: $medium-start) {
				right: 72%;
				top: -220%;
				bottom: 23%;
				background: linear-gradient(188.03deg, #FFFFFF 42.32%, rgba(255, 255, 255, 0) 73.95%);
			}
		}
		&__3 {
			/* shape-3 */
			position: absolute;
			left: -6%;
			right: 68%;
			top: 88%;
			border: 1px solid #ECC435;
			transform: rotate(135deg);
			@media screen and (min-width: $medium-start) {
				left: 62%;
				right: 30%;
				bottom: 0;
				top: initial;
			}
		}
		&__4 {
			/* shape-4 */
			position: absolute;
			left: 42%;
			right: 30%;
			@media screen and (min-width: $medium-start) {
				left: 75%;
				right: 16%;
			}
			top: -93%;
			bottom: -93%;
			background: #ECC435;
			transform: rotate(45deg);
			z-index: 1;
		}
		&__5 {
			/* shape-5 */
			position: absolute;
			left: 41.28%;
			right: 24.06%;
			top: -78.86%;
			bottom: -133.71%;
			background: linear-gradient(188.03deg, #FFFFFF 3%, rgba(255, 255, 255, 0) 96.95%);
			opacity: 0.2;
			transform: rotate(-135deg);
			@media screen and (min-width: $medium-start) {
				left: 66.28%;
				right: 11.06%;
				top: -151.86%;
				transform: rotate(45deg);
			}
		}
		&__6 {
			/* shape-6 */
			position: absolute;
			left: 74.89%;
			right: -25.56%;
			top: -29.86%;
			bottom: -100%;
			background: linear-gradient(188.03deg, #FFFFFF 3%, rgba(255, 255, 255, 0) 73.95%);
			opacity: 0.1;
			transform: rotate(45deg);
			@media screen and (min-width: $medium-start) {
				left: 84.89%;
				right: -15.56%;
				top: -29.86%;
				bottom: -140%;
				background: linear-gradient(189.73deg, #FFFFFF 9.32%, rgba(255, 255, 255, 0) 73.95%);
				opacity: 0.2;
				transform: rotate(-135deg);
			}
		}
	}

	.container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		@media (min-width: $medium-start) {
			padding: 0;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}
	}

	&__content {
		background-color: $color-accent1;
		position: relative;
		width: 100%;
		@media screen and (min-width: $medium-start) {
			background-color: transparent;
			z-index: 3;
		}
	}

	&__title {
		@extend .font-h4new;
		color: white;
		text-align: center;
		margin-top: 20px;
		@media screen and (min-width: $medium-start) {
			font-size: 48px !important;
			margin: 0;
			margin-right: 50px;
		}
	}

	&__link {
		display: flex;
		justify-content: center;
		margin: 0 auto 20px;
		@media screen and (min-width: $medium-start) {
			margin: 0;
		}
		&:hover {
			text-decoration: none;
		}
	}
}

.download-the-app-txt {
  height: 60px;
}

.email-verified-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: calc(100vh - 41.5rem);
    @media screen and (max-width: $small-end) {
      margin-bottom: calc(100vh - 44.5rem);
    }
}

.email-verified-txt {
    @extend .poppins;
    display: inline-block;
    width: 100%;
    max-width: 850px;
    font-size: 28px;
    font-weight: 600;
    line-height: 150%;
    color: #000;
    text-align: center;
    @media screen and (max-width: $small-end) {
        font-size: 24px;
    }
    .sub {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 300;
        line-height: 150%;
        color: $color-main-text;
        @media screen and (max-width: $small-end) {
            font-size: 14px;
        }
    }
    .blue {
        display: inline-block;
        color: $color-accent1 !important;
    }
}

.email-verified-img {
    margin-top: 40px;
    width: 140px;
    height: 140px;
}