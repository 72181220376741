.confirm-email {
	&_container {
		@media screen and (max-width: $small-start) {
			padding-right: 40px;
			padding-left: 40px;
		}
		.success-component {
			max-width: 620px;
			margin: 0 auto 20px;
			@media screen and (min-width: $medium-start) {
				margin: 0 auto 40px;
			}
		}
	}
	&_mt {
		margin-top: 40px;
		@media screen and (min-width: $medium-start) {
			margin-top: 80px;
		}
	}
	&__text {
		@extend .font-m1;
		text-align: center;
		line-height: 125% !important;
		@media screen and (max-width: $small-start) {
			font-size: 18px !important;
			line-height: 22.5px !important;
		}
	}
	&__buttons {
		padding: 0 25px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		@media screen and (min-width: $medium-start) {
			flex-direction: row;
		}
		&_mt {
			margin-top: 20px;
			@media screen and (min-width: $medium-start) {
				margin-top: 26px;
			}
		}
		.button {
			@media screen and (min-width: $medium-start) {
				width: 50%;
				max-width: 160px;
			}
			@media screen and (max-width: $small-start) {
				width: 100%;
				max-width: unset;
			}
			&:first-child {
				margin-bottom: 20px;
				@media screen and (min-width: $medium-start) {
					margin-bottom: 0;
					margin-right: 30px;
				}
			}
		}
	}
}

.confirm-dropdown-img {
	margin-left: 10px;
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg);
	transition: 0.3s;
	transition-property: all;
	transition-duration: 0.3s;
	transition-timing-function: ease;
	&.up {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
}