.social {
  &__link {
    margin: 0 5px;
    &:hover {
      transition: all 0.2s;
      transform: scale(1.1);
    }
  }

  &__icon {
    cursor: pointer;
    height: 50px;
    @media screen and (max-width: $small-start) {
      height: 30px;
    }
  }
}
