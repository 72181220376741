.news-and-social {
    .title {
        margin-bottom: 40px;
    }
    .news-column, .article {
        width: calc(68% - 15px);
        max-width: calc(68% - 15px);

        display: flex;
        flex-direction: column;
    }
    .social-column {
        margin-left: 30px;
        width: calc(32% - 15px);
        max-width: calc(32% - 15px);

        .twitter-timeline {

            iframe {
                width: 100% !important;
            }
        }
      
    }

    .article, .news-column .news-item {
        box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
        padding: 40px;
        margin-bottom: 40px;

        hr, h3, button, .bottom-info {
            margin-top: 30px;
        }

        .lazy-load-image-background {
            width: 55%;
        }
        img {
            width: 100%;
        }
        hr {
            width: 80px;
            height: 5px;
            background-color: $color-accent1-dark;
            border: none;
        }
        h3 {
            @extend .font-news-header;
            margin-bottom: 0;
        }
        p {
            @extend .font-news;
            margin: 40px 0 20px 0;
        }
        button {
            width: 260px;
            a {
                color: #fff;
            }
        }
        .bottom-info {
            margin-top: 20px;
            margin-left: 0;
            margin-right: 0;
            align-items: center;

            &:first-of-type {
                margin-top: 30px;
            }
            img {
                width: 24px;
                height: 24px;
            }
            span {
                @extend .font-news-bottom-info;
            }
            &>span {
                margin-left: 10px;
            }
        }
    }
    .pagination-component {
        align-self: center;
        justify-content: center;
        border: none;
        margin-top: 40px;
    }

    @media screen and (max-width: $medium-end) {
        .title {
            margin-bottom: 20px; 
        }
        .news-column, .article {
            width: calc(100vw - 60px);
            max-width: 500px;
        }
        .social-column {
            margin-top: 40px;
            margin-left: 0;
            width: calc(100vw - 60px);
            max-width: 500px;
        }
        .article, .news-column .news-item {
            padding: 40px;
            display: flex;
            flex-direction: column;

            hr, h3, button, .bottom-info {
                margin-top: 20px;
            }

            hr {
                margin-bottom: 0;
            }

            .lazy-load-image-background {
                width: 85%;
            }
            img {
                width: 100%;
            }
            button {
                width: 150px;
            }
            .bottom-info {
                &:first-of-type {
                    margin-top: 20px;
                }
            }
        }
        .article {
            margin-bottom: 0;
        }
        .pagination-component {
            margin-bottom: 0;
            margin-top: 0;
        }
    }
}