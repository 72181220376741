.short-line {
  width: 50px;
  height: 4px;
  background: $color-accent1;
  margin-bottom: 27px;
  &_yellow {
    background-color: $color-accent2;
  }
  &_mb20 {
    margin-bottom: 20px;
  }
}

.border-white-2 {
  border: 2px solid #FFFFFF !important;
}

.border-left {
  &_none {
    border-left: 0;
  }
  &_md {
    @media (min-width: $medium-start) {
      border-left: solid 2px $color-light-gray;
    }
  }
}

.schedule-border {
  border: solid 1px $color-secondary-text;
  @media screen and (min-width: $medium-start) {
    border: solid 2px $color-secondary-text;
  }
}
