.title {
  &__short-line {
    background-color: $color-accent1;
    width: 20px;
    height: 2px;
    @media (min-width: $medium-start) {
      width: 40px;
      height: 4px;
      background: $color-accent1;
    }
    &_yellow {
      background-color: $color-accent2;
    }
  }
  &__text {
    @extend .font-h4new-h2;
  }
}
