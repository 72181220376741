$top_px: (16, 40, 81, 129,);

@each $size in $top_px {
  .padding-top-#{$size}px {
    padding-top: $size+px;
  }
}

$bottom_px: (47, 80);

@each $size in $bottom_px {
  .padding-bottom-#{$size}px {
    padding-bottom: $size+px;
  }
}

$left_px: (30, 35, 50, 54, 60);

@each $size in $left_px {
  .padding-left-#{$size}px {
    padding-left: $size+px;
  }
}

.pl-lg-58px {
  @media (min-width: $large-start) {
    padding-left: 58px !important;
  }
}

$right_px: (45, 50, 60,);

@each $size in $right_px {
  .padding-right-#{$size}px {
    padding-right: $size+px;
  }
}

$padding-x_px: (10, 30);

@each $size in $padding-x_px {
  .padding-x-#{$size}px {
    padding: 0 $size+px;
  }
}

.padding-inherit {
  padding: inherit;
}
