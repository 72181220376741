* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}
.z-index-999 {
  z-index: 999;
}
.content {
  flex: 1 0 auto;
  overflow: hidden;
}
.footer {
  flex: 0 0 auto;
}

.Select-menu-outer {
  z-index: 999 !important;
}

.pre-wrap {
  white-space: pre-wrap;
}

.logo-large-img {
  width: 140px;
  height: 140px;
  @media screen and (min-width: $medium-start) {
    width: 210px;
    height: 210px;
  }
}

input, .input, .registration-form__field, .form-control, .search-player-textfield {
  border-bottom: 2px solid $color-d1;
  &:focus {
    color: #000;
    border-color: #80bdff !important;
  }
  &::placeholder {
    color: $color-d1;
  }
  color: #000;
}

.css-wh54hb-control {
  border-bottom: 2px solid $color-d1;
}

.css-a4pvsj-control {
  border-bottom: 2px solid #80bdff !important;
}

.css-107lb6w-singleValue {
  margin-left: 0 !important;
}

.css-dyr6gj-container {
  .css-1oscnvr-placeholder {
    color: $color-secondary-text;
    margin-left: 0;
  }
  .css-4p5oan-placeholder {
    @extend .font-m2;
    color: #D1D1D1;
    margin-left: 0;
  }
  .css-1uccc91-singleValue, .css-slybng-singleValue{
    font-family: Source Sans Pro, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;

    color: #000;
    margin-left: 0;
    overflow: visible;
  }
  .css-15b9idc-control, .css-wh54hb-control, .css-ny062u-control{
    min-height: 36px !important;
  }
}

input[id^='react-select-'] {
  box-shadow: none !important;
}

.border-none {
  border: none !important;
}

.line-height-120 {
  line-height: 120%;
}

.disabled-link {
  pointer-events: none;
}