$color-accent1: #008FD7;
$color-accent1-dark: #0082C4;
$color-accent2: #ECC435;
$color-accent3: #E34CB1;
$color-light-gray: #F2F2F2;
$color-bg-icon: #EFEFEF;
$color-main-text: #545454;
$color-secondary-text: #B3B3B3;
$color-d1: #D1D1D1;
$color-e5: #E5E5E5;
$color-bg-text1: #4CB1E3;
$color-bg-text2: #F8FAFB;
$red: #D70024;

$minimal-end: 360px;
$extra-small-end: 575px;
$small-start: 576px;
$small-end: 767px;
$medium-start: 768px;
$medium-end: 991px;
$large-start: 992px;
$large-end: 1199px;
$extra-large: 1200px;

$font-family-bebas-neue: 'Bebas Neue', sans-serif;
$font-family-source-sans-pro: 'Source Sans Pro', sans-serif;
$font-family-lora: 'Lora', sans-serif;
$font-family-roboto: roboto;
