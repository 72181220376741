.footer {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 28px 0;
    margin: 40px 0 0;
    height: 150px;
    &.footer-home {
        margin: 0;
    }
    @extend .font-roboto-15;
    @media screen and (min-width: $medium-start) {
        justify-content: start;
        height: 100px;
    }
}

.footer-left-text {
    white-space: nowrap;
    display: block;
    font-size: 18px;
    @extend .source-sans-pro;
    @extend .color-white;
    text-align: center;
    opacity: 0.5;
    margin-bottom: 10px;
    @media screen and (min-width: $medium-start) {
        margin-bottom: 0;
        display: inline !important;
    }
    @media screen and (max-width: $small-end) {
        font-size: 15px;
        font-family: Roboto, sans-serif !important;
    }
}

.footer-right-text {
    cursor: pointer;
    white-space: nowrap;
    display: block;
    font-size: 18px;
    @extend .source-sans-pro;
    @extend .color-white;
    text-align: center;
    @media screen and (min-width: $medium-start) {
        display: inline !important;
    }
    @media screen and (max-width: $small-end) {
        font-family: Roboto, sans-serif !important;
        font-size: 15px;
    }
    a {
        color: inherit;
    }
}

.footer-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: $medium-start) {
        flex-direction: row;
    }
}
