.contact-us {
  &__container {
    padding: 35px 0 0;
    display: flex;
    flex-direction: row;
    >div {
      min-height: 445px;
    }
    @media screen and (max-width: $small-start) {
      padding: 35px 20px 0;
    }
    @media screen and (max-width: $small-end) {
      flex-direction: column;
    }
  }
  &__form {
    max-width: 100%;
    @media screen and (min-width: $medium-start) {
      max-width: 66.67%;
      margin: 0 auto;
      padding-right: 2.3rem;
    }
  }
  &__info {
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    @media screen and (min-width: $medium-start) {
      padding-top: 0;
      flex: 0 0 33.33%;
      max-width: 33.33%;
      justify-content: space-between;
      align-items: flex-start;
      padding-left: 20px;
    }
  }
  &__blue {
    @extend .bg-contact-us-blue;
    padding: 37px 80px;
    font-size: 15px;
    @media (max-width: $medium-start) {
      padding: 20px 20px;
    }
  }
  &__message-block {
    height: 160px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $small-start) {
      height: 140px;
    }
  }
  &__textfield {
    background-color: white;
    resize: none;
    border: 2px solid #FFFFFF;
    @extend .white-sans;
    font-size: 15px;
    margin: 20px 0;
    border-radius: 0;
  }
  &__input-wrapper {
    margin-bottom: 30px;
    padding: 0;
    &_left-md {
      @media (min-width: $medium-start) {
        padding-right: 15px;
      }
    }
    &_right-md {
      @media (min-width: $medium-start) {
        padding-left: 15px;
      }
    }
  }
  &__input {
    width: 100%;
    color: #FFFFFF;
    height: 36px;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-bottom: 2px #FFFFFF solid;
    background: transparent;
    &::placeholder {
      @extend .grey-sans;
      font-size: 15px;
      line-height: 36px;
    }
    &:focus {
      color: #fff;
    }
    &_error {
      border-bottom: 2px red solid;
    }
  }
  &__selected-item {
    border-bottom: 2px #FFFFFF solid;
    background: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.isOpen {
      border-color: #80bdff;
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.2);
    }
  }
  &__selected-text {
    color: #FFFFFF;
    height: 29px;
    background: transparent;
    width: 90%;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: break-word;
    &.default-value {
      color: $color-secondary-text;
    }
  }
  &__logo {
    margin-bottom: 40px;
    height: 140px;
    align-self: center;
    @media screen and (min-width: $medium-start) {
      margin-top: 17px;
      height: 210px;
      align-self: flex-start;
    }
  }
  .dropdown {
    margin-top: 4.5px;
  }
}

.dropdown-up {
  position: absolute;
  right: 5px;
}

.info-block {
  border-left: 4px $color-accent1 solid;
  min-height: 182px;
  margin-bottom: 40px;
  padding-left: 16px;

  @media screen and (max-width: $small-end) {
    min-height: 126px;
    max-height: 126px;
    margin-bottom: 49px;
    border-left: none;
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
}

.select-error {
  border-bottom: 2px $red solid;
}

.drop-item {
  min-width: 300px;
  background-color: $color-accent1 !important;
}

.field-error {
  position: absolute;
  bottom: -15px;
  @extend .error__message;
  color: #ECC435;
}
