.team-errors-box {
  width: 100%;
}

.team-error-wrapper {
  width: 620px;
  @media screen and (max-width: $medium-end) {
    width: 85%;
    max-width: 460px;
  }
}

.team-error-wrapper:first-child {
    margin-top: 20px;
}

.team-error-wrapper:not(:last-child) {
    margin-bottom: 10px;
}

.team-error {
  @extend .source-sans-pro;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 115%;
  color: #FE0202;
  min-height: 40px;
  background: #FDE5E0;
  border-radius: 10px;
  padding: 0 10px;
  @media screen and (max-width: $small-start) {
    font-size: 13px;
  }
  span {
    padding: 10px 0;
  }
  .cross {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 10px 10px 10px 20px;
    margin: -10px;
    justify-self: flex-end;
    cursor: pointer;
  }
  .warning {
    padding-right: 10px;
  }
}