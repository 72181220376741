@font-face {
  font-display: block;
  font-family: Lora;
  src: url('./../assets/fonts/Lora-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: block;
  font-family: Lora;
  src: url('./../assets/fonts/Lora-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: block;
  font-family: Bebas Neue;
  src: url('./../assets/fonts/BebasNeue-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: block;
  font-family: Poppins;
  src: url('./../assets/fonts/Poppins-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: block;
  font-family: Poppins;
  src: url('./../assets/fonts/Poppins-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: block;
  font-family: Poppins;
  src: url('./../assets/fonts/Poppins-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: block;
  font-family: Poppins;
  src: url('./../assets/fonts/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: block;
  font-family: Poppins;
  src: url('./../assets/fonts/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: block;
  font-family: Poppins;
  src: url('./../assets/fonts/Poppins-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: block;
  font-family: Source Sans Pro;
  src: url('./../assets/fonts/SourceSansPro-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: block;
  font-family: Source Sans Pro;
  src: url('./../assets/fonts/SourceSansPro-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: block;
  font-family: Source Sans Pro;
  src: url('./../assets/fonts/SourceSansPro-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: block;
  font-family: Source Sans Pro;
  src: url('./../assets/fonts/SourceSansPro-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: block;
  font-family: Source Sans Pro;
  src: url('./../assets/fonts/SourceSansPro-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

.font {
  font-display: block;
  font-style: normal;
  line-height: 100%;
}

.bebas-neue {
  font-display: block;
  font-family: $font-family-bebas-neue;
  font-weight: normal;
}

.source-sans-pro {
  font-family: $font-family-source-sans-pro;
}

.roboto {
  font-display: block;
  font-family: $font-family-roboto;
}

.color-accent1 {
  color: $color-accent1;
}

.color-main-text {
  color: $color-main-text;
}

.color-secondary-text {
  color: $color-secondary-text;
}

.color-white {
  color: white !important;
}

.color-black {
  color: black !important;
}

.color-red {
  color: $red;
}

.color-yellow {
  color: $color-accent2;
}

.fw-normal {
  font-weight: normal !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.font-h1 {
  @extend .bebas-neue;
  @extend .font;
  font-size: 72px;
  font-weight: normal;
}

.font-h2 {
  @extend .bebas-neue;
  @extend .font;
  font-size: 48px;
}


.font-h2_2 {
  @extend .bebas-neue;
  @extend .font;
  font-size: 48px;
}

.font-h3 {
  @extend .bebas-neue;
  @extend .font;
  font-size: 36px;
}

.font-h4new {
  @extend .bebas-neue;
  @extend .font;
  font-size: 36px;

  @media screen and (max-width: $medium-end) {
    font-size: 30px;
    line-height: 30px;
  }
}

.font-h4 {
  @extend .bebas-neue;
  @extend .font;
  font-size: 24px;
}

.font-h6 {
  @extend .bebas-neue;
  @extend .font;
  font-size: 20px;
}

.font-h6_bold {
  @extend .bebas-neue;
  @extend .font;
  font-size: 20px;
}

.font-h7 {
  @extend .bebas-neue;
  @extend .font;
  font-size: 16px;
}

.font-h8 {
  @extend .bebas-neue;
  @extend .font;
  font-size: 48px;

  @media screen and (max-width: $medium-end) {
    font-size: 26px;
  }
}

.font-btn {
  @extend .bebas-neue;
  @extend .font;
  font-size: 18px;
}

.font-m1 {
  @extend .source-sans-pro;
  @extend .font;
  font-weight: 600;
  font-size: 20px;
}

.font-m2 {
  @extend .source-sans-pro;
  @extend .font;
  font-weight: 600;
  font-size: 15px;
}

.font-m3 {
  @extend .source-sans-pro;
  @extend .font;
  font-weight: normal;
  font-size: 15px;
}

.font-m4 {
  @extend .source-sans-pro;
  @extend .font;
  font-weight: 600;
  font-size: 12px;
}

.font-m5 {
  @extend .source-sans-pro;
  @extend .font;
  font-weight: 600;
  font-size: 30px;
}

.font-m6 {
  @extend .source-sans-pro;
  @extend .font;
  font-weight: 600;
  font-size: 14px;
}

.font-13pts {
  @extend .source-sans-pro;
  font-size: 13px;
  line-height: 125%;
  @media screen and (min-width: $medium-start) {
    font-size: 15px;
  }
}

.font-roboto-15 {
  @extend .roboto;
  @extend .font;
  font-weight: normal;
  font-size: 15px;
}

.font-12-20 {
  @extend .source-sans-pro;
  font-style: normal;
  line-height: 125%;
  font-weight: normal;
  font-size: 12px;
  @media screen and (min-width: $medium-start) {
    font-size: 18px;
  }
}

.font-15-20 {
  @extend .source-sans-pro;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  @media screen and (min-width: $medium-start) {
    font-size: 20px;
  }
}

.font-h3-m3 {
  @extend .font-m3;
  @media (min-width: $medium-start) and (max-width: ($large-start - 1)) {
    font-weight: 600;
    font-size: 10px;
  }
  @media screen and (max-width: $small-end) {
    // need to get form .font-h3
    font-weight: bold;
    font-size: 36px;
  }
}

.font-h3-h4 {
  @extend .font-h4;
  @media screen and (max-width: $small-end) {
    // need to get form .font-h3
    font-weight: bold;
    font-size: 36px;
  }
}

.font-h3-h1 {
  @extend .font-h1;
  @media screen and (max-width: $small-end) {
    // need to get form .font-h3
    font-size: 30px;
  }
}

.font-h3-h2 {
  @extend .font-h2;
  @media screen and (max-width: $small-end) {
    // need to get form .font-h3
    font-weight: bold;
    font-size: 36px;
  }
}

.font-h4new-h2 {
  @extend .font-h2;
  @media screen and (max-width: $small-end) {
    // need to get form .font-h4new
    font-size: 34px;
  }
}

.font-h4new-h3 {
  @extend .font-h3;
  @media screen and (max-width: $small-end) {
    // need to get form .font-h4new
    font-size: 30px;
  }
}

.font-h3new-h4 {
  @extend .font-h3;
  @media screen and (max-width: $small-end) {
    // need to get form .font-h4new
    font-size: 24px;
  }
}

.color-white-black {
  color: black !important;
  @media screen and (max-width: $small-end) {
    color: white !important;
  }
}

.color-black-accent1 {
  color: $color-accent1 !important;
  @media screen and (max-width: $small-end) {
    color: black !important;
  }
}

$font-sizes: (10, 11, 12, 13, 15, 16, 18, 20, 24, 30, 36);

@each $size in $font-sizes {
  .font-size-#{$size} {
    font-size: $size + px;
  }
}

$font-sizes-responsive: (10, 11, 12, 13, 16, 18, 20, 24);

@each $size in $font-sizes-responsive {
  .font-size-responsive-#{$size} {
    font-size: $size + px;
    @media screen and (max-width: $extra-large) {
      font-size: $size*0.9 + px;
    }
    @media screen and (max-width: $large-start) {
      font-size: $size*0.72 + px;
    }
  }
}

.black-sans {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  color: #000;
}

.white-sans {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: #FFFFFF !important;
}

.grey-sans {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: $color-secondary-text;
}

.red-sans {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: $red;
}

.poppins {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
}

.nextline-wrap {
  word-wrap: break-word;
}

.first-letter-capitalize:first-letter {
  text-transform: capitalize;
}

.font-size-36-ms-30 {
  font-size: 36px;
  @media screen and (max-width: $small-end) {
    font-size: 30px;
  }
}

.lora {
  font-family: $font-family-lora;
}


.font-l1 {
  @extend .lora;
  font-size: 16px !important;
  @media screen and (min-width: $medium-start) {
    font-size: 20px !important;
  }
}

.font-l2 {
  @extend .lora;
  font-size: 13px !important;
  @media screen and (min-width: $medium-start) {
    font-size: 16px !important;
  }
}

.font-l3 {
  @extend .lora;
  font-size: 16px !important;
}

.font-l4 {
  @extend .lora;
  font-size: 14px !important;
  @media screen and (min-width: $large-start) {
    font-size: 16px !important;
  }
}


.font-p1 {
  @extend .poppins;
  font-size: 26px;
  line-height: 125%;

  @media screen and (max-width: $medium-end) {
    font-size: 20px;
  }
}

.font-p2 {
  @extend .poppins;
  font-size: 16px;
  line-height: 125%;
  font-weight: 400;

  @media screen and (max-width: $medium-end) {
    font-size: 16px;
    line-height: 22px;
  }
}

.font-p3 {
  @extend .poppins;
  font-size: 16px;
  line-height: 125%;
  font-weight: 275;
}

.font-p4 {
  @extend .poppins;
  font-size: 22px;
  line-height: 125%;

  @media screen and (max-width: $medium-end) {
    font-size: 20px;
  }
}

.font-p5 {
  @extend .black-sans;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
}

.font-rules-and-faq {
  color: #000;
  font-family: Lora, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-line;
  line-height: 22px;
}

.font-news-header {
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  white-space: pre-line;
  line-height: 32px;
}

.font-news {
  color: #000;
  font-family: Lora, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-line;
  line-height: 22px;
}

.font-news-bottom-info {
  color: #000;
  font-family: Lora, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  white-space: pre-line;
  line-height: 22px;
}
