$top_px: (-188, -70, 15, 20, 25, 30, 40, 50, 58, 65, 70, 75, 80, 87, 90, 100, 150);

@each $size in $top_px {
  .margin-top-#{$size}px {
    margin-top: $size+px !important;
  }
}

$bottom_px: (10, 15, 16, 20, 27, 30, 40, 48, 60, 65, 80);

@each $size in $bottom_px {
  .margin-bottom-#{$size}px {
    margin-bottom: $size+px !important;
  }
}

$bottom_md_px: (20, 30);

@each $size in $bottom_md_px {
  .margin-bottom-md-#{$size}px {
    @media (min-width: $medium-start) {
      margin-bottom: $size+px !important;
    }
  }
}

$top_md_px: (10, 20, 40);

@each $size in $top_md_px {
  .margin-top-md-#{$size}px {
    @media (min-width: $medium-start) {
      margin-top: $size+px !important;
    }
  }
}

$left_md_px: (50, 90);

@each $size in $left_md_px {
  .margin-left-md-#{$size}px {
    @media screen and (min-width: $medium-start) {
      margin-left: $size+px;
    }
  }
}

.margin-left-0-10-20-50 {
  @media (min-width: $medium-start) and (max-width: $large-start) {
    margin-left: 10px;
  }
  @media (min-width: $large-start) and (max-width: $extra-large) {
    margin-left: 20px;
  }
  @media screen and (min-width: $extra-large) {
    margin-left: 50px;
  }
}

.margin-top-14-30px {
  margin-top: 14px;
  @media (min-width: $medium-start) {
    margin-top: 30px;
  }
}

.margin-top-20-40px {
  margin-top: 20px;
  @media (min-width: $medium-start) {
    margin-top: 40px;
  }
}

.margin-bottom-20-40px {
  margin-bottom: 20px;
  @media (min-width: $medium-start) {
    margin-bottom: 40px;
  }
}

.margin-right-20px {
  margin-right: 20px;
}

.margin-left-minus15px {
  margin-left: -15px;
}

.margin-right-minus15px {
  margin-right: -15px;
}

.margin-left-10px {
  margin-left: 10px;
}

.margin-left-15px {
  margin-left: 15px;
}

.margin-right-10px {
  margin-right: 10px;
}