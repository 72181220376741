.width-inherit {
  width: inherit;
}

.max-width-100percent {
  max-width: 100% !important;
}

.max-width-sm-250px {
  @media screen and (max-width: $small-end) {
    max-width: 250px;
  }
}

$width_px: (15, 45, 60, 90, 235, 280, 340);

@each $size in $width_px {
  .width-#{$size}px {
    width: $size+px;
  }
}

.w-md-75 {
  // width: 75%;
  @media screen and (min-width: $medium-start) {
    width: 75%;
  }
}

.mw-auto {
  min-width: auto !important;
}