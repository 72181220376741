.pagination-component {
    border: 1px solid $color-d1;
    list-style: none;
    display: flex;
    margin-bottom: 0;
    &__page, &__break, &__previous, &__next {
        border: 1px solid $color-d1;
        @extend .font-m3;
        @media screen and (min-width: $medium-start) {
            font-size: 18px !important;
        }
    }
    &__page-link, &__break-link, &__previous-link, &__next-link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        min-width: 38px;
        height: 38px;
        @media screen and (min-width: $medium-start) and (max-width: $medium-end) {
            min-width: 50px;
            height: 50px;
        }
        @media screen and (min-width: $large-start) {
            min-width: 60px;
            height: 60px;
        }
        &:hover {
            box-shadow: 0 0 6px $color-accent2 inset;
        }
    }
    &__page {
        background: $color-light-gray;
        &_active {
            background-color: $color-accent1;
        }
    }
    &__page-link {
        &_active {
            color: white;
            font-weight: 600;
            &:hover {
                color: white;
            }
        }
        &:hover {
            text-decoration: none;
        }
    }
    &__previous, &__next {
        background: url('../assets/images/arrow-pagination.svg') no-repeat center;
        width: 58px;
        @media screen and (min-width: $medium-start) {
            width: 90px;
            background-size: 10%;
        }
		@media screen and (min-width: $large-start) {
            width: 124px;
        }
    }
    &__next {
        transform: rotate(180deg);
    }
    &__break {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-light-gray;
        :before {
            content: '';
            @media screen and (min-width: $medium-start) {
                content: '..';
            }
        }
    }
    &__break-link {
        @media screen and (max-width: $small-end) {
            min-width: 10px;
        }
    }
}