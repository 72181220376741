.registration-form {
	@extend .font-m2;
	max-width: 550px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	.success-message-wrapper {
		max-width: 620px;
		width: 100%;
		@media screen and (min-width: $medium-start) {
			width: calc(100% + 70px);
		}
		margin-bottom: 45px;
		margin-top: -20px;
	}
	&_mt {
		margin-bottom: 0px;
	}
	&__field-wrapper {
		@media (max-width: $small-end) {
			width: 85%;
			&.btn-wrapper {
				width: calc(50% - 20px);
				max-width: calc(50% - 20px);
			}
		}
		margin-bottom: 28px;
		padding: 0;
		width: 100%;
		@media (min-width: $medium-start) {
			margin-bottom: 23px;
			padding: 0 15px 0 0;
			&:nth-child(2n) {
				padding: 0 0 0 15px;
				.input-label {
					left: 15px;
				}
			}
		}
		&:focus {
			box-shadow: 0 0 5px $color-accent1;
			transition: 0.2s;
		}
		&.is-logged-variant {
			margin-bottom: 50px;
			@media (max-width: $small-start) {
				margin-bottom: 46px;
			}
		}
	}
	&__field {
		// resset bootstrap start
		border-top: none;
		border-left: none;
		border-right: none;
		border-radius: 0;
		padding: 0;
		// resset bootstrap end
		&.uneditable {
			color: $color-secondary-text;
			-webkit-text-fill-color: $color-secondary-text;
		}
		&.is-logged-variant {
			&::placeholder {
				color: $color-secondary-text;
				-webkit-text-fill-color: $color-secondary-text;
			}
			&.locked {
				color: $color-secondary-text;
				-webkit-text-fill-color: $color-secondary-text;
			}
		}
		@extend .font-m3;
		&::placeholder{
			 font-weight: 600;
		 }
		&:disabled {
			background-color: unset !important;
		}
	}
	@media screen and (max-width: $small-end) {
		.button_medium {
			width: 85%;
		}
		.row{
			align-items: center;
			justify-content: center;
		}
	}
	.last-mobile {
		margin-top: -5px;
		@media screen and (min-width: $medium-start) {
			width: 260px;
		}
	}
	&.is-logged-variant {
		margin-top: 0;
	}
}

.confirm-terms {
	margin: 0 0 20px 5px;
	height: 40px;
	display: flex;
	align-items: center;
	&.is-logged-variant {
		margin: -15px 0 25px 5px;
		@media (min-width: $small-start) {
			margin: -25px 0 35px 5px;
		}
	}
	@media (max-width: $medium-start) {
		width: 85%;
		margin-bottom: 40px;
		padding-left: 20px;
		padding-right: 0;
	}
	span {
		padding-left: 5px;
	}
	.form-check-input {
		margin-top: 0 !important;
		height: 15px;
		width: 15px;
	}
}
.text-country {
	display: block;
	color: $color-secondary-text;
	text-align: center;
	@extend .font-13pts;
	@media (min-width: $medium-start) {
		position: absolute;
		top: -25%;
		left: 105%;
		width: 180px;
		box-sizing: content-box;
		visibility: hidden;
	}
	@media (min-width: $large-start) {
		visibility: visible;
		text-align: left;
	}
	@media (min-width: $extra-large) {
		position: absolute;
		top: 10%;
		left: 100%;
		width: 280px;
		box-sizing: content-box;
	}
	&_indents {
		margin-top: 10px;
		@media (min-width: $extra-large) {
			margin: 0;
			padding-left: 10px;
		}
	}
}
.error {
	&__field {
		border-bottom-color: red !important;
	}
	&__message {
		@extend .font-m6;
		color: red;
		white-space: pre-line;
	}
}

.input-label {
	color: $color-d1;
	font-weight: 600;
	font-size: 13px;
	position: absolute;
	left: 0;
	top: -20px;
}

.change-pwd {
	margin-top: -12px;
}