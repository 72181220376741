.counters-wrapper-row {
    margin-top: 20px;
    margin-bottom: 40px;
}

.counters-wrapper {
    padding-top: 30px;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
}

.counters-row {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse;
}

.counters-item {
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .number {
        display: flex;
        margin-bottom: 5px;
        .digit {
            @extend .font-h8;
            color: white;
            background: url('../assets/images/number-background.svg') no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            width: 40px;
            background-size: 100% 100%;
            margin-left: 2px;
        }
    }
    @media screen and (max-width: $medium-end) {
        .number {
            .digit {
                padding-top: 2px;
                height: 31px;
                width: 20px;
            }
        }
    }
}