.private-league-settings-modal {
    &.own {
        .league-code {
            @extend .font-l3;
        }
        .join-code-img {
            width: 20px;
            height: 20px;
        }
        .league-code {
            margin-bottom: 20px;
        }
        .checkbox-wrapper {
            @extend .font-p5;
            margin: 20px auto 30px auto;
            display: block;
            position: relative;
            padding-left: 25px;
            cursor: pointer;
            user-select: none;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkmark:after {
                    display: block;
                }
            }
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 15px;
                width: 15px;
                border: 1px solid #000000;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;

                    left: 4px;
                    top: 1px;
                    width: 5px;
                    height: 10px;
                    border: solid black;
                    border-width: 0 3px 3px 0;
                    transform: rotate(45deg);
                }
            }
        }

        button {
            margin-bottom: 20px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .select-no-options, input {
            font-family: Source Sans Pro, sans-serif;
            font-style: normal;
            font-size: 15px;
            line-height: normal;
        }

        input {
            opacity: 1 !important;
        }
    }

    .hover-tooltip {
        position: relative;
        cursor: not-allowed;

        &:before {
            content: attr(data-hover);
            @extend .source-sans-pro;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 100%;
            visibility: hidden;
            padding: 8px;
            opacity: 0;
            width: 140px;
            background-color: black;
            color: #fff;
            text-align: center;
            border-radius: 5px;
            position: absolute;
            z-index: 1;
            left: 10px;
            top: 75%;
        }

        &:hover:before {
            opacity: 1;
            visibility: visible;
        }
    }
}